import { SuggestionOption } from 'blocks/SearchableField/types';
import { SignUpSchema } from 'blocks/sign-up/schemas';
import {
  useState,
  useCallback,
  createContext,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
} from 'react';
import { DiscountRes } from 'services/UserService/UserService';

import { MembershipType, PriceType } from 'types/types';

export type CreditCardType = {
  number: string;
  cvc: string;
  expMonth: string;
  expYear: string;
  name: string;
};

export type CreditCardErrorType = {
  number: boolean;
  cvc: boolean;
  expMonth: boolean;
  expYear: boolean;
  name: boolean;
};

export type JoinUsDialogueStateType = {
  isLoading: boolean;
  memberShipsWithPrices: MembershipType[];
  price: PriceType;
  errors: any;
  errorMsgs: any;
  discount?: DiscountRes;
} & SignUpSchema;

type JoinUsContextType = {
  joinUsState: JoinUsDialogueStateType;
  setState: Dispatch<SetStateAction<JoinUsDialogueStateType>>;
  handleTextChange: ChangeEventHandler<HTMLInputElement>;
  handleCheckBoxChange: (name: string, value: boolean) => void;
  handleSelectChange: (name: string, value: string) => void;
  handleCreditCardChange: (key: keyof CreditCardType, value: string) => void;
  handleCityChange: (name: string, value: SuggestionOption) => void;
  handleResetForm: () => void;
};

const initialJoinUsState: JoinUsDialogueStateType = {
  formType: 'default',
  isLoading: false,
  memberShipsWithPrices: [],
  currency: '',
  memberShipCategory: 'Leisure',
  membershipId: null,
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  password: '',
  confirmPassword: '',
  city: {
    id: 0,
    name: '',
  },
  countryCode: '',
  dateOfBirth: '',
  referralCode: '',
  instagram: {
    consent: '',
    username: '',
  },
  children: {
    shouldAdd: '',
    list: [],
  },
  whatsAppConsent: false,
  termsAccept: false,
  price: {
    value: '',
    symbol: '',
    code: '',
  },
  creditCard: {
    brand: '',
    name: '',
    number: '',
    expiry: '',
    cvc: '',
  },

  errorMsgs: {},

  errors: {
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    password: false,
    confirmPassword: false,
    city: false,
    countryCode: false,
    dateOfBirth: false,
    referralCode: false,
    instagram: {
      consent: false,
      username: false,
    },
    children: {
      shouldAdd: false,
      list: false,
    },
    whatsAppConsent: false,
    termsAccept: false,
    creditCard: {
      name: false,
      number: false,
      expiry: {
        month: false,
        year: false,
      },
      cvc: false,
    },
  },
};

const useJoinUs: () => JoinUsContextType = () => {
  const [state, setState] =
    useState<JoinUsDialogueStateType>(initialJoinUsState);
  const handleTextChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    e => {
      const key = e.target.name as keyof JoinUsDialogueStateType;

      const { errors } = initialJoinUsState;

      setState(state => ({
        ...state,
        errors,
        [key]: e.target.value,
      }));
    },
    []
  );

  const handleCheckBoxChange = useCallback((name: string, value: boolean) => {
    const key = name as keyof JoinUsDialogueStateType;
    setState(state => ({
      ...state,
      errors: {
        ...initialJoinUsState.errors,
      },
      [key]: value,
    }));
  }, []);

  const handleSelectChange = useCallback((name: string, value: string) => {
    const key = name as keyof JoinUsDialogueStateType;
    setState(state => ({
      ...state,
      errors: {
        ...initialJoinUsState.errors,
      },
      [key]: value,
    }));
  }, []);

  const handleCreditCardChange = useCallback(
    (key: keyof CreditCardType, value: string) => {
      setState(state => ({
        ...state,
        errors: {
          ...state.errors,
          creditCard: {
            ...initialJoinUsState.errors.creditCard,
          },
        },
        errorMsgs: {},
        creditCard: {
          ...state.creditCard,
          [key]: value,
        },
      }));
    },
    []
  );
  const handleCityChange = useCallback(
    (name: string, option: SuggestionOption) => {
      const key = name as keyof JoinUsDialogueStateType;
      setState(state => ({
        ...state,
        errors: {
          ...initialJoinUsState.errors,
        },
        cityName: option.name,
        [key]: option.id,
      }));
    },
    []
  );

  const handleResetForm = useCallback(() => {
    setState(state => ({
      ...initialJoinUsState,
      memberShipsWithPrices: state.memberShipsWithPrices,
      currency: state.currency,
      memberShipCategory: state.memberShipCategory,
    }));
  }, []);

  return {
    joinUsState: state,
    setState,
    handleTextChange,
    handleCheckBoxChange,
    handleCityChange,
    handleSelectChange,
    handleCreditCardChange,
    handleResetForm,
  };
};

export const JoinUsContext = createContext<JoinUsContextType>({
  joinUsState: initialJoinUsState,
  setState: () => {},
  handleTextChange: () => {},
  handleCheckBoxChange: () => {},
  handleSelectChange: () => {},
  handleCreditCardChange: () => {},
  handleResetForm: () => {},
  handleCityChange: () => {},
});

export default useJoinUs;
